<template>
  <b-modal id="modal-new-return-file" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-50">Subir arquivo de retorno</h3>
      <span class="mb-2"> Anexe o seu arquivo abaixo. </span>
      <b-row>
        <b-col cols="12">
          <v-select
            v-model="operacao"
            :options="operacoes"
            class="assignor-selector d-flex flex-column mr-2 mb-1"
            placeholder="Selecione uma operacao"
          />
          <v-select
            v-model="template"
            :options="templates"
            class="assignor-selector d-flex flex-column mr-2"
            placeholder="Escolha um template"
          />
          <cc-dropzone v-model="file" subtitle="" accept-formats=".TXT, .REM, .xlsx" />
        </b-col>
      </b-row>

      <div class="d-flex flex-row justify-content-center mt-2 w-100">
        <b-button
          variant="outline-secondary"
          class="mb-1 mr-1 text-dark font-weight-bolder"
          @click="$bvModal.hide('modal-new-return-file')"
        >
          Cancelar
        </b-button>
        <b-button
          variant="secondary"
          class="mb-1 bg-light-secondary font-weight-bolder"
          :disabled="!file"
          @click="uploadReturnFile"
        >
          Subir arquivo de retorno
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue'
import http from '@/services/http'

export default {
  name: 'UploadReturnFileModal',
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    CcDropzone,
    vSelect,
  },
  data() {
    return {
      file: '',
      templates: [
        { value: 'qitech', label: 'QITech' },
        { value: 'cartos', label: 'Cartos' },
        { value: 'bmp', label: 'BMP' },
        { value: 'trademaster', label: 'Trademaster' }
      ],
      template: '',
      operacoes: [],
      operacao: '',
    }
  },
  mounted() {
    this.getOperacoes()
  },
  methods: {
    async uploadReturnFile() {
      this.$swal({
        title: 'Confirmar upload?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
        preConfirm: async () => {
          this.$swal.showLoading()

          await this.$store.dispatch('admin/uploadReturnFile', { file: this.file, template: this.template.value, operacao: this.operacao.value })

          this.$bvModal.hide('modal-new-return-file')
          this.$emit('uploaded')

          this.$swal({
            title: 'Sucesso!',
            text: 'Arquivo de retorno enviado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        },
      })
    },
    acceptFormats() {
      [".RET, .TXT, .REM .xlsx`"]
    },
    getOperacoes() {
      http.get('/backoffice/operacoes')
      .then((response) => {
        this.operacoes = response.data.data.map((operacao) => ({
          value: operacao.id,
          label: operacao.nome,
        }))
      })
    }
  },
}
</script>
